exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenges-challenge-one-js": () => import("./../../../src/pages/challenges/challengeOne.js" /* webpackChunkName: "component---src-pages-challenges-challenge-one-js" */),
  "component---src-pages-challenges-challenge-three-js": () => import("./../../../src/pages/challenges/challengeThree.js" /* webpackChunkName: "component---src-pages-challenges-challenge-three-js" */),
  "component---src-pages-challenges-challenge-two-js": () => import("./../../../src/pages/challenges/challengeTwo.js" /* webpackChunkName: "component---src-pages-challenges-challenge-two-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-details-analyst-js": () => import("./../../../src/pages/details/analyst.js" /* webpackChunkName: "component---src-pages-details-analyst-js" */),
  "component---src-pages-details-frontdev-js": () => import("./../../../src/pages/details/frontdev.js" /* webpackChunkName: "component---src-pages-details-frontdev-js" */),
  "component---src-pages-details-javadev-js": () => import("./../../../src/pages/details/javadev.js" /* webpackChunkName: "component---src-pages-details-javadev-js" */),
  "component---src-pages-details-phpdev-js": () => import("./../../../src/pages/details/phpdev.js" /* webpackChunkName: "component---src-pages-details-phpdev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-landingcx-js": () => import("./../../../src/pages/landingcx.js" /* webpackChunkName: "component---src-pages-landingcx-js" */),
  "component---src-pages-link-js": () => import("./../../../src/pages/link.js" /* webpackChunkName: "component---src-pages-link-js" */),
  "component---src-pages-projects-cabletel-js": () => import("./../../../src/pages/projects/cabletel.js" /* webpackChunkName: "component---src-pages-projects-cabletel-js" */),
  "component---src-pages-projects-cotizacion-asincronica-js": () => import("./../../../src/pages/projects/cotizacionAsincronica.js" /* webpackChunkName: "component---src-pages-projects-cotizacion-asincronica-js" */),
  "component---src-pages-projects-desarrollo-mvp-js": () => import("./../../../src/pages/projects/desarrolloMvp.js" /* webpackChunkName: "component---src-pages-projects-desarrollo-mvp-js" */),
  "component---src-pages-projects-fdigital-js": () => import("./../../../src/pages/projects/fdigital.js" /* webpackChunkName: "component---src-pages-projects-fdigital-js" */),
  "component---src-pages-projects-firmadigital-js": () => import("./../../../src/pages/projects/firmadigital.js" /* webpackChunkName: "component---src-pages-projects-firmadigital-js" */),
  "component---src-pages-projects-qdetect-js": () => import("./../../../src/pages/projects/qdetect.js" /* webpackChunkName: "component---src-pages-projects-qdetect-js" */),
  "component---src-pages-projects-wecover-js": () => import("./../../../src/pages/projects/wecover.js" /* webpackChunkName: "component---src-pages-projects-wecover-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-sure-js": () => import("./../../../src/pages/sure.js" /* webpackChunkName: "component---src-pages-sure-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terminos-condiciones-js": () => import("./../../../src/pages/terminos-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-condiciones-js" */)
}

